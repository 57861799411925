<template>
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5884 13.7879C11.2056 15.3289 11.0117 16.9038 11.0117 18.4932V19.351C11.0117 20.9356 11.2056 22.5153 11.5884 24.0514L11.8598 25.1224H27.2211L27.4877 24.0514C27.8705 22.5153 28.0643 20.9356 28.0643 19.351V18.4932C28.0643 16.9086 27.8705 15.3289 27.4877 13.7928L27.2211 12.717H11.8598L11.5932 13.7879H11.5884ZM27.8511 8.84029H11.2298L11.9179 10.391H27.163L27.8511 8.84029ZM11.191 28.9991H27.8899L27.2696 27.4484H11.8113L11.191 28.9991ZM9.80028 11.3504L8.85049 9.21342C8.74388 8.96629 8.68573 8.69976 8.68573 8.43324C8.68573 7.372 9.54345 6.51428 10.6047 6.51428H28.4762C29.5375 6.51428 30.3952 7.372 30.3952 8.43324C30.3952 8.69976 30.337 8.96629 30.2304 9.21342L29.2806 11.3504L29.7507 13.2258C30.182 14.9461 30.4 16.7148 30.4 18.4932V19.351C30.4 21.1245 30.182 22.8933 29.7507 24.6184L29.3242 26.3193L30.2595 28.655C30.3516 28.8876 30.4 29.1299 30.4 29.377C30.4 30.4528 29.5278 31.3251 28.452 31.3251H10.6338C9.55798 31.3251 8.68573 30.4528 8.68573 29.377C8.68573 29.1299 8.73419 28.8828 8.82626 28.655L9.76151 26.3193L9.33507 24.6184C8.90379 22.8933 8.68573 21.1245 8.68573 19.3461V18.4932C8.68573 16.7197 8.90379 14.9509 9.33507 13.2258L9.80028 11.3504Z"
      fill="currentColor"
    />
    <path
      d="M16.8747 16.3112V21.7143H15.7614L13.5905 18.0924V21.7143H12.4772V16.3112H13.5905L15.7651 19.9367V16.3112H16.8747Z"
      fill="currentColor"
    />
    <path
      d="M19.9474 19.7883H18.5706V18.9199H19.9474C20.1601 18.9199 20.3333 18.8853 20.4669 18.816C20.6005 18.7443 20.6982 18.6453 20.76 18.5192C20.8219 18.393 20.8528 18.2507 20.8528 18.0924C20.8528 17.9316 20.8219 17.7819 20.76 17.6434C20.6982 17.5048 20.6005 17.3935 20.4669 17.3094C20.3333 17.2253 20.1601 17.1832 19.9474 17.1832H18.9565V21.7143H17.8432V16.3112H19.9474C20.3704 16.3112 20.7328 16.3878 21.0347 16.5412C21.339 16.6921 21.5715 16.9012 21.7323 17.1684C21.8931 17.4356 21.9735 17.7411 21.9735 18.085C21.9735 18.4338 21.8931 18.7356 21.7323 18.9905C21.5715 19.2453 21.339 19.442 21.0347 19.5805C20.7328 19.719 20.3704 19.7883 19.9474 19.7883Z"
      fill="currentColor"
    />
    <path
      d="M23.855 16.3112V21.7143H22.7417V16.3112H23.855ZM27.0909 16.3112L24.9497 18.9793L23.714 20.3078L23.5136 19.2539L24.3485 18.111L25.7253 16.3112H27.0909ZM25.8255 21.7143L24.2335 19.2168L25.0573 18.5266L27.1429 21.7143H25.8255Z"
      fill="currentColor"
    />
  </svg>
</template>