<script setup>
import { useLangStore } from '@/stores/useLangStore';
import { useMapStore } from '@/stores/useMapStore';
import { computed } from 'vue';

const props = defineProps({
  children: Array,
  active: Boolean,
  index: Number,
  scroll: Boolean
})

const store = useMapStore(),
  langStore = useLangStore()

let chosenLang = computed(() => langStore.chosenLangGetter)

const isActive = (tab) => {
  if (!tab.children) {
    return JSON.stringify(tab.id) == JSON.stringify(store.chosenMenuTabIdGetter)
  }

  let res = false;

  tab.children.map(el => {
    if (el.children) {
      el.children.map(child1 => {
        if (child1.children) {
          child1.children.map(child2 => {
            if (JSON.stringify(child2.id) == JSON.stringify(store.chosenMenuTabIdGetter)) res = true;
          })
        }
        else {
          if (JSON.stringify(child1.id) == JSON.stringify(store.chosenMenuTabIdGetter)) res = true;
        }
      })
    }
    else {
      if (JSON.stringify(el.id) == JSON.stringify(store.chosenMenuTabIdGetter)) res = true;
    }
  })

  return res;
}
</script>

<template>
  <div
    class="tab-secondary"
    :class="{ 'tab-secondary--active': props.active }"
  >
    <div class="tab-secondary__inner">
      <slot></slot>

      <svg
        v-if="props.children?.length"
        class="tab-secondary__icon"
        width="4"
        height="8"
        viewBox="0 0 4 8"
        fill="#FCFCFC"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.89391 4.3109L0.960528 7.87122C0.819055 8.04293 0.58969 8.04293 0.448231 7.87122L0.106102 7.45596C-0.0351304 7.28454 -0.0354022 7.00672 0.105498 6.8349L2.43025 3.99999L0.105498 1.1651C-0.0354021 0.993276 -0.0351303 0.715456 0.106102 0.544038L0.448232 0.128784C0.589705 -0.0429277 0.81907 -0.0429277 0.960528 0.128784L3.89391 3.68909C4.03536 3.8608 4.03536 4.13918 3.89391 4.3109Z"
        />
      </svg>
    </div>

    <div
      class="children"
      :class="{'children--scroll': props.scroll}"
      v-if="props.children"
    >
      <Tab2
        v-for="child of props.children"
        :active="isActive(child)"
        :index="props.index"
        @click.stop="store.chooseMenuTabId(child?.id, props.index)"
      >
      {{ child.name?.[chosenLang.id] || child.name }}
    </Tab2>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tab-secondary {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 5px;

  &__inner {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 146px;
    padding: 10px 12px;
    border-radius: 10px;
    font-family: Roboto;
    font-weight: 400;
    color: #fff;
    background: #14141380;
    backdrop-filter: blur(15px);
    transition: .2s;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.5);
    }
  }

  &--active>&__inner {
    background: rgba(153, 52, 4, 0.1);
    backdrop-filter: blur(20px);
    border: 1px solid rgba(236, 112, 20, 0.4);
  }

  &__icon {
    opacity: 0.3;
    transition: .2s;
    margin-left: auto;
  }

  &:hover &__icon {
    opacity: 1;
  }

  &:hover .children {
    display: flex;
  }
}

.children {
  display: none;
  position: absolute;
  padding: 8px;
  top: -8px;
  left: calc(100%);
  padding-left: 5px;
  //max-height: 0px;
  //overflow: hidden;
  flex-direction: column;
  gap: 6px;
  transition: .5s;

  &--scroll{
    max-height: 400px;
    overflow: auto;
  }
}
</style>