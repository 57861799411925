<template>
  <header class="header">
    <div
      class="logo logo--big"
      title="Ukrainian Hydrometeorological Institute of the SES of Ukraine and NAS of Ukraine"
    >
      <img
        class="logo__img"
        src="@/assets/images/uhmi.svg"
        alt="institute-icon"
      />
      <h1 class="logo__text">
        <template v-if="chosenLang.id == 'ua'">
          Український гідрометеорологічний інститут<br /> ДСНС України та НАН України
        </template>
        <template v-else-if="chosenLang.id == 'en'">
          Ukrainian Hydrometeorological Institute<br /> of the SES of Ukraine and NAS of Ukraine
        </template>
      </h1>
    </div>

    <div
      class="logo logo--small"
      title="Laboratory of the river systems modelling"
    >
      <img
        class="logo__img"
        src="@/assets/images/lab-of-the-river-systems-model-logo.svg"
        alt="laboratory-icon"
      />
      <div class="logo__divider"></div>
      <img
        class="logo__text"
        v-if="chosenLang.id == 'en'"
        src="@/assets/images/lab-of-the-river-systems-model-text-en.svg"
        alt="laboratory-text"
      />
      <img
        class="logo__text"
        v-else-if="chosenLang.id == 'ua'"
        src="@/assets/images/lab-of-the-river-systems-model-text-ua.svg"
        alt="laboratory-text"
      />
    </div>

    <div class="name">
      <img
        src="@/assets/images/logo1.png"
        class="name__logo"
      />
      <h2 class="name__name">
        AgroStats
      </h2>
      <h3 class="name__tagline">
        <template v-if="chosenLang.id == 'ua'">
          Статистика посівних площ, добрив та урожайності в Україні
        </template>
        <template v-else-if="chosenLang.id == 'en'">
          Statistics of sown areas, fertilizers, and crop yield in Ukraine
        </template>
      </h3>
    </div>

    <nav
      class="nav"
      data-html2canvas-ignore
    >
      <h3
        class="nav__item"
        @click="useTogglePopup('AboutPopup')"
      >
        <template v-if="chosenLang.id == 'ua'">
          Про платформу
        </template>
        <template v-else-if="chosenLang.id == 'en'">
          About
        </template>
      </h3>
      <h3 class="nav__item nav__item--lang">
        {{ langs.filter(lang => lang.id !== chosenLang.id)[0].name }}
        <svg
          width="12"
          height="6"
          viewBox="0 0 12 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.5464 5.76117L1.03996 1.42113C0.822618 1.21182 0.822618 0.872466 1.03996 0.663174L1.56556 0.156981C1.78253 -0.0519766 2.13418 -0.0523787 2.35166 0.156088L5.93992 3.59564L9.52816 0.156088C9.74564 -0.0523787 10.0973 -0.0519766 10.3143 0.156981L10.8399 0.663174C11.0572 0.872489 11.0572 1.21184 10.8399 1.42113L6.33344 5.76117C6.1161 5.97046 5.76374 5.97046 5.5464 5.76117Z"
            fill="#FCFCFC"
          />
        </svg>

        <div class="dropdown">
          <div class="dropdown__content">
            <div
              class="lang"
              v-for="lang in langs"
              :class="{ 'lang--active': lang.id == chosenLang.id }"
              @click="store.changeLang(lang.id)"
            >
              {{ lang.name }}
            </div>
          </div>
        </div>
      </h3>
    </nav>

    <svg
      class="header__burger"
      width="48"
      height="36"
      viewBox="0 0 48 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="toggleMenu"
    >
      <rect
        x="6"
        y="6"
        width="36"
        height="4"
        rx="2"
        fill="white"
      />
      <rect
        x="6"
        y="16"
        width="36"
        height="4"
        rx="2"
        fill="white"
      />
      <rect
        x="6"
        y="26"
        width="36"
        height="4"
        rx="2"
        fill="white"
      />
    </svg>

    <div
      class="header__menu"
      @touchstart="touchStartHandler"
      @touchend="touchEndHandler"
    >
      <div class="mobileMenu">
        <div
          class="mobileMenu__item"
          @click="useTogglePopup('ParamsPopup')"
        >
          {{ mapStore.mainTabNameGetter?.[chosenLang.id] }}
          <template v-if="mapStore.mainTabNameGetter?.en !== 'Irrigation'">
            <br />
            {{ mapStore.getMenuTabName(mapStore.chosenMenuTabIdGetter)?.[chosenLang.id] ||
              mapStore.getMenuTabName(mapStore.chosenMenuTabIdGetter) }}
          </template>
        </div>

        <div class="mobileMenu__item mobileMenu__item--double">
          <div
            class="year"
            @click="openChooseYear(0)"
          >{{ selectedTime[0] }}</div>
          <template v-if="selectedTimeMode !== 'One Year'">
            &nbsp;-&nbsp;
            <div
              class="year"
              @click="openChooseYear(1)"
            >{{ selectedTime[1] }}</div>
          </template>
        </div>

        <div
          class="mobileMenu__item"
          :class="{ 'mobileMenu__item--inactive': mapStore.measurmentsGetter?.id?.length <= 1 }"
          v-html="mapStore.measurmentsGetter?.id[mapStore.chosenMeasurmentIndexGetter].name?.[chosenLang.id]"
          @click="useTogglePopup('MeasurmentPopup')"
        ></div>

        <div
          class="mobileMenu__item"
          @click="chosenRegionTypeNumber = +!chosenRegionTypeNumber; emits('region-change', props.regionTypes[chosenRegionTypeNumber].value)"
        >
          {{ props.regionTypes[chosenRegionTypeNumber].name?.[chosenLang.id] }}
        </div>

        <div class="mobileMenu__info">
          <div
            class="mobileMenu__item"
            @click="useTogglePopup('AboutPopup')"
          >
            <template v-if="chosenLang.id == 'ua'">
              Про платформу
            </template>
            <template v-else-if="chosenLang.id == 'en'">
              About
            </template>
          </div>

          <div
            class="mobileMenu__item"
            @click="store.changeLang(langs.filter(lang => lang.id !== chosenLang.id)[0].id)"
          >
            {{ langs.filter(lang => lang.id !== chosenLang.id)[0].name }}
          </div>
        </div>

        <div class="logo1">
          <img
            class="logo1__img"
            src="@/assets/images/uhmi.svg"
            alt="institute-icon"
          />
          <h1 class="logo1__text">
            <template v-if="chosenLang.id == 'ua'">
              Український гідрометеорологічний інститут<br /> ДСНС України та НАН України
            </template>
            <template v-else-if="chosenLang.id == 'en'">
              Ukrainian Hydrometeorological Institute<br /> of the SES of Ukraine and NAS of Ukraine
            </template>
          </h1>
        </div>

        <div
          class="logo1 logo1--small"
          title="Laboratory of the river systems modelling"
        >
          <img
            class="logo1__img"
            src="@/assets/images/lab-of-the-river-systems-model-logo.svg"
            alt="laboratory-icon"
          />
          <img
            class="logo1__text-img"
            v-if="chosenLang.id == 'en'"
            src="@/assets/images/lab-of-the-river-systems-model-text-en.svg"
            alt="laboratory-text"
          />
          <img
            class="logo1__text-img"
            v-else-if="chosenLang.id == 'ua'"
            src="@/assets/images/lab-of-the-river-systems-model-text-ua.svg"
            alt="laboratory-text"
          />
        </div>
      </div>
    </div>
  </header>

  <YearsPopup :yearIndex="yearIndex" />
</template>

<script setup>
import { useLangStore } from '@/stores/useLangStore';
import { computed, ref } from 'vue';
import { useTogglePopup } from "@/composables/popup";
import { useMapStore } from '@/stores/useMapStore';
import YearsPopup from "@/components/Years-popup.vue";
import { storeToRefs } from 'pinia';

const props = defineProps(['regionTypes']),
  chosenRegionTypeNumber = ref(0),
  emits = defineEmits(['region-change']),
  yearIndex = ref(0)

const store = useLangStore(),
  mapStore = useMapStore()

let lastTouchPos = null,
  selectedTimeMode = storeToRefs(mapStore).selectedTimeModeGetter,
  selectedTime = storeToRefs(mapStore).yearsGetter

const langs = computed(() => store.langsGetter),
  chosenLang = computed(() => store.chosenLangGetter)

const toggleMenu = () => {
  document.querySelector('.header__menu').classList.toggle('header__menu--expanded');
}

const touchStartHandler = (e) => {
  lastTouchPos = e.changedTouches[0].clientX;
}

const touchEndHandler = (e) => {
  let diff = lastTouchPos - e.changedTouches[0].clientX;
  if (diff < -20) toggleMenu();
}

const openChooseYear = (index) => {
  yearIndex.value = index;
  useTogglePopup('YearsPopup')
}
</script>

<style lang="scss" scoped>
.header {
  top: 0;
  position: relative;
  z-index: 100;
  width: 100%;
  height: 60px;
  padding: 0 36px;
  display: flex;
  flex-direction: row;
  font-family: 'Roboto';

  background-color: #141413cc;
  background: rgba(153, 52, 4, 0.2);
  backdrop-filter: blur(150px);

  &--screenshot {
    background-color: red;
  }

  &__burger,
  &__menu {
    display: none;
  }
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #e6e6e6;

  &--big {
    max-width: 260px;
    gap: 16px;
    font-size: 10px;
    font-weight: 700;
  }

  &--small {
    width: 160px;
    margin-left: 80px;
  }

  &__text {
    font-size: inherit;
  }

  &__divider {
    position: relative;
    height: 17px;
    width: 1px;
    background-color: #fff;
    margin-left: 10px;
    margin-right: 5px;
  }
}

.name {
  width: 470px;
  display: grid;
  grid-template-areas:
    "logo name"
    "logo tagline";
  gap: 0 12px;
  margin: auto;
  color: #fff;
  height: 36px;
  justify-content: center;
  align-items: center;

  &__logo {
    grid-area: logo;
    height: inherit;
  }

  &__name {
    grid-area: name;
    font-weight: 600;
    font-size: 26px;
    line-height: 17px;
    margin: unset;
  }

  &__tagline {
    grid-area: tagline;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    margin: unset;
  }
}

.nav {
  display: flex;
  flex-direction: row;
  margin: auto 0;
  gap: 60px;
  min-width: 140px;
  margin-left: 190px;
  font-weight: 300;
  font-size: 16px;
  color: #fff;

  &__item {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    font-weight: 300;
    cursor: pointer;

    &--lang:hover {
      .dropdown {
        max-height: 68px;
      }
    }
  }
}

.dropdown {
  position: absolute;
  top: 100%;
  left: -12px;
  overflow: hidden;
  max-height: 0;
  transition: .3s;
  padding-top: 12px;

  &__content {
    background-color: #5F4A41;
    border: 1px solid #a0a0a0;
    border-radius: 0 0 12px 12px;
  }
}

.lang {
  padding: 4px 12px;
  cursor: pointer;

  &--active {
    background-color: #993404;
  }

  &:last-of-type {
    border-radius: 0 0 12px 12px;
  }
}

@media screen and (max-width: 1440px) {
  .logo {
    width: 60px;
    margin-left: 0;

    &__text,
    &__divider {
      display: none;
    }
  }

  .nav {
    margin: unset;
  }
}

@media screen and (max-width: 800px) {
  .header {
    padding: 0 12px;
    justify-content: space-between;

    &__burger {
      display: block;
      margin: auto 0;
    }

    &__menu {
      display: flex;
      position: absolute;
      width: 100vw;
      height: calc(100dvh - 60px);
      top: 60px;
      left: 100vw;
      z-index: 998;
      background-color: #141413a0;
      transition: .3s;

      &--expanded {
        left: 0;
      }
    }

    .mobileMenu {
      position: relative;
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 12px;
      margin-left: 10%;
      background-color: #141413cc;
      padding: 24px 12px;
      color: #fff;

      &__item {
        font-size: 24px;
        font-weight: 500;
        padding: 8px 12px;
        border: 2px solid #a0a0a0;
        border-radius: 12px;
        text-align: end;

        &--inactive {
          border: 2px solid #303030;
        }

        &--double {
          display: flex;
          flex-direction: row;
          align-items: center;
          border: unset;

          .year {
            padding: 4px 8px;
            border: 2px solid #a0a0a0;
            border-radius: 12px;
          }
        }
      }

      &__info {
        display: inherit;
        flex-direction: inherit;
        gap: inherit;
        align-items: inherit;
        margin: auto 0;
        font-size: 24px;
      }
    }
  }

  .logo {
    display: none;

    &1 {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 36px;

      &__img {
        width: 36px;
        height: fit-content;
      }

      &__text {
        margin-left: auto;
        font-size: 18px;
        line-height: 1.4;
        //text-transform: uppercase;

        &-img {
          width: 100%;
        }
      }
    }
  }

  .name {
    margin: auto 0;
    width: auto;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    &__tagline {
      display: none;
    }
  }

  .nav {
    display: none;
  }
}
</style>