<template>
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.7036 7.59998C26.6423 7.59998 24.9712 9.27104 24.9712 11.3323V12.3502C24.9712 12.7234 25.2766 13.0288 25.6498 13.0288H26.6678C28.729 13.0288 30.4001 11.3577 30.4001 9.29649V8.27858C30.4001 7.90535 30.0947 7.59998 29.7215 7.59998H28.7036ZM21.6418 8.75784C21.3789 8.49489 20.9463 8.49489 20.6833 8.75784L20.1998 9.23711C18.6093 10.8276 18.6093 13.4063 20.1998 14.9968L20.6409 15.4379L19.3473 16.7314C19.2031 15.5736 18.6899 14.4496 17.7992 13.559L17.32 13.0797C17.057 12.8167 16.6244 12.8167 16.3614 13.0797L15.8822 13.559C14.2917 15.1495 14.2917 17.7281 15.8822 19.3186L16.3233 19.7597L15.0297 21.0533C14.8855 19.8954 14.3723 18.7715 13.4816 17.8808L13.0024 17.3973C12.7394 17.1344 12.3068 17.1344 12.0438 17.3973L11.5646 17.8766C9.97408 19.4671 9.97408 22.0458 11.5646 23.6362L12.0057 24.0773L9.08341 26.9996C8.55325 27.5297 8.55325 28.3907 9.08341 28.9209C9.61357 29.451 10.4746 29.451 11.0047 28.9209L13.927 25.9986L14.4444 26.5161C16.0349 28.1066 18.6136 28.1066 20.204 26.5161L20.6833 26.0368C20.9463 25.7739 20.9463 25.3412 20.6833 25.0783L20.204 24.599C19.2794 23.6744 18.1004 23.1527 16.8916 23.034L18.2446 21.681L18.762 22.1985C20.3525 23.7889 22.9312 23.7889 24.5217 22.1985L25.0009 21.7192C25.2639 21.4562 25.2639 21.0236 25.0009 20.7607L24.5217 20.2814C23.5971 19.3568 22.418 18.8351 21.2092 18.7164L22.5622 17.3634L23.0796 17.8808C24.6701 19.4713 27.2488 19.4713 28.8393 17.8808L29.3186 17.3973C29.5815 17.1344 29.5815 16.7018 29.3186 16.4388L28.8393 15.9553C27.393 14.509 25.336 14.051 23.4995 14.5769C24.0254 12.7404 23.5631 10.6834 22.1211 9.23711L21.6418 8.75784Z"
      fill="currentColor"
    />
  </svg>
</template>