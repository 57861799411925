<template>
  <div
    class="popup__wrapper"
    id="MeasurmentPopup"
    @click="useTogglePopup('MeasurmentPopup')"
  >
    <div
      class="popup"
      @click.stop
    >
      <div
        class="popup__cross"
        @click="useTogglePopup('MeasurmentPopup')"
      >
        <svg
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="26"
            cy="26"
            r="25"
            fill="#FFF7BC"
            stroke="#EC7014"
            stroke-width="2"
          />
          <path
            d="M16.7368 15.293L36.6186 35.1748"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
          />
          <path
            d="M15.2928 35.2339L35.1747 15.3521"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </div>
      <div class="popup__heading">
        <template v-if="chosenLang.id == 'ua'">
          Оберіть одиниці вимірювання
        </template>
        <template v-else-if="chosenLang.id == 'en'">
          Choose a measurment unit
        </template>
      </div>

      <div class="measurments">
        <div
          class="measurment"
          v-for="(item, index) of mapStore?.measurmentsGetter?.id"
          :class="{ 'measurment--active': mapStore.chosenMeasurmentIndexGetter == index }"
          :key="item?.queue"
          v-html="item?.name?.[chosenLang.id]"
          @click="mapStore.chooseMeasurment(index)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useTogglePopup } from "@/composables/popup";
import { useLangStore } from "@/stores/useLangStore";
import { useMapStore } from "@/stores/useMapStore";
import { computed, onMounted, ref } from "vue";

const props = defineProps(['yearIndex'])

const langStore = useLangStore(),
  mapStore = useMapStore()

const chosenLang = computed(() => langStore.chosenLangGetter)

const updateYears = (year) => {
  mapStore.updateYears([mapStore.yearsGetter[+!props.yearIndex], (year + 1980 - 1)].sort((a, b) => a - b))
}
</script>

<style lang="scss" scoped>
.popup {
  position: absolute;
  width: 1084px;
  height: 700px;
  top: 140px;
  margin-left: calc((100vw - 1084px) / 2);
  scale: 0.5;
  transition: .5s;
  background-color: #FFFBDE;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  font-family: "Roboto";

  &__cross {
    position: absolute;
    right: -18px;
    top: -18px;
    cursor: pointer;
  }

  &__wrapper {
    position: absolute;
    width: 100vw;
    height: 100dvh;
    left: 0;
    top: 0;
    background-color: #00000080;
    z-index: -1;
    transition: .5s;
    opacity: 0;

    &--active {
      opacity: 1;
      z-index: 999;
      cursor: pointer;

      .popup {
        scale: 1;
        cursor: auto;
      }
    }
  }

  &__heading {
    font-size: 40px;
    text-align: center;
    font-weight: 500;
  }
}

.measurment {
  width: fit-content;
  margin: 0 auto;
  padding: 4px 8px;
  border: 2px solid #a0a0a0;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  border-radius: 8px;
  transition: .3s;

  &s {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin: 24px 0;
  }

  &--active {
    background-color: #993404a0;
    border-color: #EC7014;
    color: #fff;
  }
}

@media screen and (max-width: 1100px) {
  .popup {
    width: 90vw;
    margin-left: 5vw;
  }
}

@media screen and (max-height: 900px) {
  .popup {
    top: calc(60px + 2.5dvh);
    height: calc(100dvh - 60px - 5dvh);
  }
}
</style>