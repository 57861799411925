<template>
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.14288 10.0598C8.14305 9.55146 8.34507 9.06398 8.70452 8.70452C9.06398 8.34507 9.55146 8.14305 10.0598 8.14288H27.9402C28.4486 8.14305 28.9361 8.34507 29.2955 8.70452C29.655 9.06398 29.857 9.55146 29.8572 10.0598V27.9402C29.857 28.4486 29.655 28.9361 29.2955 29.2955C28.9361 29.655 28.4486 29.857 27.9402 29.8572H10.0598C9.55146 29.857 9.06398 29.655 8.70452 29.2955C8.34507 28.9361 8.14305 28.4486 8.14288 27.9402V10.0598ZM27.6768 9.42019H23.0657C23.0378 9.4685 23.0036 9.51295 22.9642 9.5524L9.5524 22.9642C9.51297 23.0037 9.46852 23.038 9.42019 23.066V27.6768L27.6768 9.42019ZM28.5799 14.934V10.3233L10.3233 28.5799H14.9343C14.9615 28.5326 14.9953 28.4879 15.0359 28.4477L28.4477 15.0359C28.4879 14.9953 28.5326 14.9615 28.5799 14.934ZM28.5799 21.3206V16.7098L16.7098 28.5799H21.3209C21.348 28.5326 21.3819 28.4879 21.4224 28.4477L28.4477 21.4224C28.4879 21.3819 28.5326 21.348 28.5799 21.3206ZM21.2902 9.42019H16.6792C16.6512 9.4685 16.6171 9.51295 16.5776 9.5524L9.5524 16.5776C9.51297 16.6172 9.46852 16.6514 9.42019 16.6795V21.2902L21.2902 9.42019ZM28.5799 23.0964L23.0964 28.5799H27.9402C28.1098 28.5797 28.2724 28.5122 28.3923 28.3923C28.5122 28.2724 28.5797 28.1098 28.5799 27.9402V23.0964ZM14.9037 9.42019H10.0598C9.89022 9.42036 9.72763 9.48781 9.60772 9.60772C9.48781 9.72763 9.42036 9.89022 9.42019 10.0598V14.9037L14.9037 9.42019Z"
      fill="currentColor"
    />
  </svg>
</template>