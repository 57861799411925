import { createApp } from 'vue'
import { createPinia } from 'pinia'
import VueGtag from "vue-gtag";
import App from './App.vue'

const pinia = createPinia()
const app = createApp(App)

app.use(pinia)
app.use(VueGtag, {
  config: { id: 'G-DYR69MCL02'}
})
app.mount('#app')
