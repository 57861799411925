import { useAPI } from "@/composables/API";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import tabs from "@/assets/content/tabs.json"
import regions from "@/assets/content/regions.json"
import geojson from "@/assets/geojson/test_agriculture_admin_obl_simplfied_0_01.geojson"
import Ukraine_geojson from "@/assets/geojson/test_agriculture_all_ukraine_simplfied_0_01.geojson"

export const useMapStore = defineStore("map", () => {
  // state
  let map = ref(),
    allMapData = ref(),
    scale = ref([">40", 40, 30, 20, "<20"]),
    selectedTimeMode = ref("Period"),
    years = ref([2011, 2020]),
    locations = ref(regions),
    chosenMenuTabId = ref(),
    flatTabs = [],
    mainTabsNames = ref([]),
    mainTabsIndex = ref(0),
    locationsAPIString = "",
    fetchTimeoutID = null,
    measurments = ref(),
    chosenMeasurmentIndex = ref(0),
    updateChartTrigger = ref(false),
    updateMapTrigger = ref(false),
    chosenRegionType = ref('regions_layer') // 'regions_layer' or 'Ukraine_layer'

  locations.value.map(el => {
    locationsAPIString += `locations=${el.name.id}&`
  })
  locationsAPIString = locationsAPIString.slice(10, -1)

  const mapChildren = (arr) => {
    if (arr == null) return;
    arr.map(el => {
      if (el.hasOwnProperty("children")) mapChildren(el.children)
      else flatTabs.push(el)
    })
  }

  if (flatTabs.length == 0) {
    tabs.map(el => {
      if (el.children) mapChildren(el.children)
      else flatTabs.push(el);
      mainTabsNames.value.push(el.name)
    })
  }

  chosenMenuTabId.value = flatTabs.find(el => el.id[0].queue == "wheat_yld_c_ha").id;
  console.log(`Default parameter:`, chosenMenuTabId.value)

  // getters
  const mapGetter = computed(() => map.value),
    allMapDataGetter = computed(() => allMapData.value),
    chosenMenuTabIdGetter = computed(() => chosenMenuTabId.value),
    scaleGetter = computed(() => scale.value),
    yearsGetter = computed(() => years.value),
    selectedTimeModeGetter = computed(() => selectedTimeMode.value),
    mainTabNameGetter = computed(() => mainTabsNames.value[mainTabsIndex.value]),
    measurmentsGetter = computed(() => measurments.value),
    chosenMeasurmentIndexGetter = computed(() => chosenMeasurmentIndex.value),
    locationsGetter = computed(() => locations.value),
    updateChartTriggerGetter = computed(() => updateChartTrigger.value),
    updateMapTriggerGetter = computed(() => updateMapTrigger.value),
    chosenRegionTypeGetter = computed(() => chosenRegionType.value)

  // actions
  async function changeRegionType(region, cb) {
    chosenRegionType.value = region;
    cb(region);

    try {
      let mapData = allMapData.value;
      let mapInstance = chosenRegionType.value == 'regions_layer' ? geojson : Ukraine_geojson;
      mapInstance.features.map(feature => {
        if (selectedTimeMode.value == "Period") {
          let mean = +mapData[feature.properties.NAME_UA].reduce((acc, val) => acc + (+val[1]), 0) / mapData[feature.properties.NAME_UA].filter(el => el[1] != null).length;
          if (mean != null && !Number.isNaN(mean)) feature.properties.value_data = mean;
          else feature.properties.value_data = -1;
        }
        else if (selectedTimeMode.value == "One Year") {
          if (mapData[feature.properties.NAME_UA] != null) feature.properties.value_data = +mapData[feature.properties.NAME_UA][0][1] || -1;
          else feature.properties.value_data = -1;
        }
        else if (selectedTimeMode.value == "Compare") {
          let filteredData = mapData[feature.properties.NAME_UA]
          if (filteredData[0][1] !== null && filteredData.at(-1)[1] !== null) feature.properties.value_data = +(filteredData.at(-1)[1] - filteredData[0][1])
          else feature.properties.value_data = -1e6;
        }
        else {
          feature.properties.value_data = +mapData[feature.properties.NAME_UA][0][1];
        }
      })

      if (chosenMenuTabId.value[chosenMeasurmentIndex.value].divide) divideLogic(mapInstance, chosenMeasurmentIndex.value);

      let dataForCurrentYear = []

      mapInstance.features.map(el => {
        dataForCurrentYear.push(el.properties.value_data)
      })

      applyScale(dataForCurrentYear)
      map.value = mapInstance;
      updateMapTrigger.value = !updateMapTrigger.value;
      updateChartTrigger.value = !updateChartTrigger.value;
    } catch (err) {
      console.error(err)
    }
  }

  function applyScale(arr) {
    if (selectedTimeMode.value != "Compare") {
      if (chosenMenuTabId.value[chosenMeasurmentIndex.value]?.scale && chosenRegionType.value == 'regions_layer') return scale.value = chosenMenuTabId.value[chosenMeasurmentIndex.value].scale;
      else if (chosenMenuTabId.value[chosenMeasurmentIndex.value]?.Ukraine) return scale.value = chosenMenuTabId.value[chosenMeasurmentIndex.value].Ukraine;
    }
    else if (selectedTimeMode.value == "Compare") {
      if (chosenMenuTabId.value[chosenMeasurmentIndex.value]?.compareUkraine && chosenRegionType.value == 'regions_layer') return scale.value = chosenMenuTabId.value[chosenMeasurmentIndex.value].compareUkraine;
      else if (chosenMenuTabId.value[chosenMeasurmentIndex.value]?.compare) return scale.value = chosenMenuTabId.value[chosenMeasurmentIndex.value].compare;
    }

    if (arr.filter(el => el !== -1e6).length == 0) return scale.value = [null];

    let max = Math.max(...arr),
      min = Math.min(...arr),
      scales = [],
      mult = 1

    if (selectedTimeMode.value == "Compare") {
      max = Math.max(...arr.filter(el => el !== -1e6))
      min = Math.min(...arr.filter(el => el !== -1e6))
      mult = 1.8
    }
    for (let i = 0; i < 5 * mult + 1; i++) {
      scales.push(Number((max - min) * ((i) / (6 * mult - 1)) + min).toFixed(4))
    }

    scale.value = [...new Set(scales.reverse())]
    return scales
  }

  async function getBasicData() {
    let mapData = (await useAPI().getMap({ locations: locationsAPIString, data_type: chosenMenuTabId.value?.[0]?.queue, years: years.value }))[0]
    //console.log(`Got from API: `, JSON.parse(JSON.stringify(mapData)))
    allMapData.value = mapData;

    let mapInstance = chosenRegionType.value == 'regions_layer' ? geojson : Ukraine_geojson;

    mapInstance.features.map(feature => {
      if (selectedTimeMode.value == "Period") {
        let mean = +mapData[feature.properties.NAME_UA].reduce((acc, val) => acc + (+val[1]), 0) / mapData[feature.properties.NAME_UA].filter(el => el[1] != null).length;
        if (mean != null && !Number.isNaN(mean)) feature.properties.value_data = mean;
        else feature.properties.value_data = -1;
      }
      else if (selectedTimeMode.value == "One Year") {
        if (mapData[feature.properties.NAME_UA] != null) feature.properties.value_data = +mapData[feature.properties.NAME_UA][0][1] || -1;
        else feature.properties.value_data = -1;
      }
      else if (selectedTimeMode.value == "Compare") {
        let filteredData = mapData[feature.properties.NAME_UA]
        if (filteredData[0][1] !== null && filteredData.at(-1)[1] !== null) feature.properties.value_data = +(filteredData.at(-1)[1] - filteredData[0][1])
        else feature.properties.value_data = -1e6;
      }
      else {
        console.error("SOMETHING WRONG")
        feature.properties.value_data = +mapData[feature.properties.NAME_UA][0][1];
      }
    })

    let dataForCurrentYear = []

    mapInstance.features.map(el => {
      dataForCurrentYear.push(el.properties.value_data)
    })
  }

  async function getMapFeature() {
    clearTimeout(fetchTimeoutID)
    fetchTimeoutID = null;
    fetchTimeoutID = setTimeout(async () => {
      let mapData = (await useAPI().getMap({ locations: locationsAPIString, data_type: chosenMenuTabId.value?.[chosenMeasurmentIndex.value]?.queue, years: years.value }))[0]
      //console.log(`Got from API: `, mapData)
      allMapData.value = mapData;

      try {
        let mapInstance = chosenRegionType.value == 'regions_layer' ? geojson : Ukraine_geojson;
        mapInstance.features.map(feature => {
          if (selectedTimeMode.value == "Period") {
            let mean = +mapData[feature.properties.NAME_UA].reduce((acc, val) => acc + (+val[1]), 0) / mapData[feature.properties.NAME_UA].filter(el => el[1] != null).length;
            if (mean != null && !Number.isNaN(mean)) feature.properties.value_data = mean;
            else feature.properties.value_data = -1;
          }
          else if (selectedTimeMode.value == "One Year") {
            if (mapData[feature.properties.NAME_UA] != null) feature.properties.value_data = +mapData[feature.properties.NAME_UA][0][1] || -1;
            else feature.properties.value_data = -1;
          }
          else if (selectedTimeMode.value == "Compare") {
            let filteredData = mapData[feature.properties.NAME_UA]
            if (filteredData[0][1] !== null && filteredData.at(-1)[1] !== null) feature.properties.value_data = +(filteredData.at(-1)[1] - filteredData[0][1])
            else feature.properties.value_data = -1e6;
          }
          else {
            feature.properties.value_data = +mapData[feature.properties.NAME_UA][0][1];
          }
        })

        if (chosenMenuTabId.value[chosenMeasurmentIndex.value].divide) divideLogic(mapInstance, chosenMeasurmentIndex.value);

        let dataForCurrentYear = []

        mapInstance.features.map(el => {
          dataForCurrentYear.push(el.properties.value_data)
        })

        applyScale(dataForCurrentYear)
        map.value = mapInstance;
        updateMapTrigger.value = !updateMapTrigger.value;
        updateChartTrigger.value = !updateChartTrigger.value;
      } catch (err) {
        console.error(err)
      }
    }, 300)
  }

  async function divideLogic(mapInstance, index) {
    //console.error("divide!", chosenMenuTabId.value[index]);

    if (chosenMenuTabId.value[index].divide == "square") {
      await getBasicData();

      let arr = []
      Object.entries(allMapData.value).map(el => {
        arr = []
        el[1].map(val => {
          arr.push([val[0], (+val[1] * 1000 * 100) / locations.value.find(loc => loc.name.id == el[0]).ha])
        })
        allMapData.value[el[0]] = arr
      })
      // for map
      arr = []
      mapInstance.features.map(el => {
        let res = (el.properties.value_data * 1000 * 100) / locations.value.find(loc => loc.name.id == el.properties.NAME_UA).ha
        arr.push(res)
        el.properties.value_data = res;
      })
      updateMapTrigger.value = !updateMapTrigger.value;
      updateChartTrigger.value = !updateChartTrigger.value;
      applyScale(arr)
    }
    else if (chosenMenuTabId.value[index].divide) {
      await getBasicData();
      let mapData = (await useAPI().getMap({ locations: locationsAPIString, data_type: chosenMenuTabId.value[index].divide, years: years.value }))[0]
      //console.log(`Got from API for divide: `, mapData)

      let arr = []
      Object.entries(allMapData.value).map(el => {
        arr = []
        let currRegion = mapData[el[0]], i = 0
        el[1].map(val => {
          // mult by 100 because %
          if (+currRegion[i][1] != 0 && +val[1] != 0) arr.push([val[0], +val[1] / +currRegion[i][1] * 100])
          else arr.push(null)
          i++
        })
        allMapData.value[el[0]] = arr
      })
      // for map
      arr = []
      mapInstance.features.map(el => {
        let currRegion = mapData[el.properties.NAME_UA]
        // divide by mean of arr
        if (el.properties.value_data != null && currRegion.filter(el => el[1] != null).length != 0) {
          let divider = currRegion.reduce((acc, val) => acc + (+val[1]), 0) / currRegion.filter(el => el[1] != null).length
          if (divider != 0) {
            let res = el.properties.value_data / divider * 100
            arr.push(res)
            el.properties.value_data = res;
          } else {
            console.error("DIVISION BY NULL or ZERO");
            arr.push(null);
          }
        }
        else {
          console.error("DIVISION BY NULL or ZERO");
          arr.push(null);
        }
      })
      updateMapTrigger.value = !updateMapTrigger.value;
      updateChartTrigger.value = !updateChartTrigger.value;
      applyScale(arr)
    }
  }

  async function chooseMeasurment(index) {
    if (chosenMeasurmentIndex.value == index) return;
    chosenMeasurmentIndex.value = index;

    if (chosenMenuTabId.value[index].divide) divideLogic(map.value, index);
    else {
      chosenMenuTabId.value = measurments.value.id;
      await getMapFeature();
    }
  }

  async function chooseMenuTabId(id, index) {
    if (!id) return;
    mainTabsIndex.value = index;
    chosenMenuTabId.value = id;
    let found = flatTabs.find(el => {
      return el.id.find(elId => {
        return elId.queue == id[0].queue
      })
    })
    measurments.value = found;
    chosenMeasurmentIndex.value = 0;
    await getMapFeature()
  }

  const getMenuTabName = (id) => {
    let found = flatTabs.find(el => {
      return el.id.find(elId => {
        return elId.queue == id[0].queue
      })
    })

    return found?.short || found.name
  }

  const updateYears = async (val) => {
    years.value = val;
    await getMapFeature();
  }

  const updateSelectedTimeMode = (val) => {
    selectedTimeMode.value = val
  }

  return { mapGetter, allMapDataGetter, chosenMenuTabIdGetter, scaleGetter, yearsGetter, selectedTimeModeGetter, mainTabNameGetter, measurmentsGetter, chosenMeasurmentIndexGetter, locationsGetter, updateChartTriggerGetter, updateMapTriggerGetter, chosenRegionTypeGetter, changeRegionType, getMapFeature, chooseMenuTabId, getMenuTabName, updateYears, updateSelectedTimeMode, chooseMeasurment }
})