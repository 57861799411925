<template>
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1651 6.54575C20.7243 5.09028 18.4202 5.02251 17.9206 6.54575C15.3239 14.524 9.77142 16.692 9.77142 22.3152C9.77142 27.2935 14.1419 31.3216 19.5429 31.3216C24.9438 31.3216 29.3143 27.2935 29.3143 22.3152C29.3143 16.6637 23.774 14.5604 21.1651 6.54575ZM19.5429 28.0849C16.1145 28.0849 13.3247 25.5437 13.3247 22.4209C13.3247 21.9738 13.7222 21.6117 14.213 21.6117C14.7038 21.6117 15.1013 21.9738 15.1013 22.4209C15.1013 24.6516 17.0939 26.4666 19.5429 26.4666C20.0336 26.4666 20.4312 26.8287 20.4312 27.2758C20.4312 27.7229 20.0336 28.0849 19.5429 28.0849Z"
      fill="currentColor"
    />
  </svg>
</template>