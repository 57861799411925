<template>
  <div
    class="popup__wrapper"
    id="ParamsPopup"
    @click="useTogglePopup('ParamsPopup')"
  >
    <div
      class="popup"
      @click.stop
    >
      <div
        class="popup__cross"
        @click="useTogglePopup('ParamsPopup')"
      >
        <svg
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="26"
            cy="26"
            r="25"
            fill="#FFF7BC"
            stroke="#EC7014"
            stroke-width="2"
          />
          <path
            d="M16.7368 15.293L36.6186 35.1748"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
          />
          <path
            d="M15.2928 35.2339L35.1747 15.3521"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </div>
      <div class="popup__content">
        <div class="popup__heading">
          <template v-if="chosenLang.id == 'ua'">
            Оберіть параметр
          </template>
          <template v-else-if="chosenLang.id == 'en'">
            Choose a parameter
          </template>
        </div>

        <div
          class="tab"
          v-for="(tab, index) of tabs"
        >
          <div
            class="tab__heading"
            :class="{ 'tab__heading--single': !tab?.children }, { 'tab__heading--active': mapStore.chosenMenuTabIdGetter?.[mapStore.chosenMeasurmentIndexGetter]?.queue == 'irg_mill_m3' && index == 3 }"
            @click="tab?.children ? toggleTab(index) : mapStore.chooseMenuTabId(tab?.id, index)"
          >
            {{ tab.name?.[chosenLang.id] || tab.name }}
            <template v-if="tab.icon == 'crop-yield.svg'">
              <CropYieldSvg />
            </template>
            <template v-else-if="tab.icon == 'crop-area.svg'">
              <CropAreaSvg />
            </template>
            <template v-else-if="tab.icon == 'fertilizers.svg'">
              <FertilizersSvg />
            </template>
            <template v-else-if="tab.icon == 'irrigation.svg'">
              <IrrigationSvg />
            </template>
          </div>

          <div
            class="tab__content"
            :class="{ 'tab__content--opened': index == 0 }"
          >
            <template v-if="tab?.children?.[1]?.children?.length > 0">
              <div
                class="tab__row"
                v-for="tab_row in tab.children"
              >
                <div class="tab__hr">
                  <div class="tab__hr-text">
                    {{ tab_row?.name?.[chosenLang.id] }}
                  </div>
                </div>
                <template v-if="tab_row.children">
                  <div
                    class="tab__item"
                    v-for="tab_item in tab_row.children"
                    :class="{ 'tab__item--active': tab_item?.id?.[mapStore.chosenMeasurmentIndexGetter]?.queue == mapStore.chosenMenuTabIdGetter?.[mapStore.chosenMeasurmentIndexGetter]?.queue }"
                    @click="mapStore.chooseMenuTabId(tab_item?.id, index)"
                  >
                    {{ tab_item?.name?.[chosenLang.id] }}
                  </div>
                </template>
                <template v-else>
                  <div
                    class="tab__item"
                    :class="{ 'tab__item--active': tab_row?.id?.[mapStore.chosenMeasurmentIndexGetter]?.queue == mapStore.chosenMenuTabIdGetter?.[mapStore.chosenMeasurmentIndexGetter]?.queue }"
                    @click="mapStore.chooseMenuTabId(tab_row?.id, index)"
                  >
                    {{ tab_row?.name?.[chosenLang.id] }}
                  </div>
                </template>
              </div>
            </template>

            <div
              class="tab__row"
              v-else
            >
              <div
                class="tab__item"
                v-for="tab_row in tab.children"
                :class="{ 'tab__item--active': tab_row?.id?.[mapStore.chosenMeasurmentIndexGetter]?.queue == mapStore.chosenMenuTabIdGetter?.[mapStore.chosenMeasurmentIndexGetter]?.queue }"
                @click="mapStore.chooseMenuTabId(tab_row?.id, index)"
              >
                {{ tab_row?.name?.[chosenLang.id] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import tabs from "@/assets/content/tabs.json"
import { useTogglePopup } from "@/composables/popup";
import { useLangStore } from "@/stores/useLangStore";
import { computed, onMounted, ref } from "vue";

// svg components
import CropAreaSvg from "@/components/svg/crop-area.vue"
import CropYieldSvg from "@/components/svg/crop-yield.vue"
import FertilizersSvg from "@/components/svg/fertilizers.vue"
import IrrigationSvg from "@/components/svg/irrigation.vue"
import { useMapStore } from "@/stores/useMapStore";

const langStore = useLangStore(),
  mapStore = useMapStore()

const chosenLang = computed(() => langStore.chosenLangGetter)

const toggleTab = (index) => {
  document.querySelectorAll('#ParamsPopup .popup .tab .tab__content').forEach((el, c) => {
    if (c !== index) el.style.maxHeight = 0;
  })
  let el = document.querySelectorAll('#ParamsPopup .popup .tab')[index].querySelector('.tab__content');
  console.log(el, el.style.maxHeight, el.scrollHeight)
  if (el.style.maxHeight == '0px' || el.style.maxHeight == 0) el.style.maxHeight = el.scrollHeight + 'px';
  else el.style.maxHeight = 0;
}

onMounted(() => {

})
</script>

<style lang="scss" scoped>
.popup {
  position: absolute;
  width: 1084px;
  height: 700px;
  top: 140px;
  margin-left: calc((100vw - 1084px) / 2);
  scale: 0.5;
  transition: .5s;
  background-color: #FFFBDE;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  font-family: "Roboto";

  &__content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow: auto;
  }

  &__cross {
    position: absolute;
    right: -18px;
    top: -18px;
    cursor: pointer;
  }

  &__wrapper {
    position: absolute;
    width: 100vw;
    height: 100dvh;
    left: 0;
    top: 0;
    background-color: #00000080;
    z-index: -1;
    transition: .5s;
    opacity: 0;

    &--active {
      opacity: 1;
      z-index: 999;
      cursor: pointer;

      .popup {
        scale: 1;
        cursor: auto;
      }
    }
  }

  &__heading {
    font-size: 40px;
    text-align: center;
    font-weight: 500;
  }
}

.tab {
  &__heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 32px;
    font-weight: 400;
    padding-left: 8px;

    svg {
      width: 64px;
      height: 64px;
      color: #EC7014;
    }

    &--single {
      border: 2px solid transparent;
      border-radius: 12px;
    }

    &--active {
      background-color: #993404a0;
      border-color: #EC7014;
      color: #fff;

      svg {
        color: #fff;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 0;
    transition: 1s;
    overflow: hidden;

    &--opened {
      // hardcode
      max-height: 206px;
    }
  }

  &__hr {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 0;
    margin: 20px 0;
    border: 1px solid #a0a0a0;

    &-text {
      font-size: 18px;
      background-color: #FFFBDE;
      padding: 0 8px;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px 6px;
  }

  &__item {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 400;
    color: #fff;
    padding: 4px 8px;
    background-color: #141413b0;
    border: 1px solid #141413b0;
    border-radius: 8px;
    transition: .3s;

    &--active {
      background-color: #993404a0;
      border-color: #EC7014;
    }
  }
}

@media screen and (max-width: 1100px) {
  .popup {
    width: 90vw;
    margin-left: 5vw;
  }
}

@media screen and (max-height: 900px) {
  .popup {
    top: calc(60px + 2.5dvh);
    height: calc(100dvh - 60px - 5dvh);
  }
}
</style>