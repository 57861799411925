import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useLangStore = defineStore("lang", () => {
  const langs = ref([
    {
      id: 'ua',
      name: "Українська"
    },
    {
      id: 'en',
      name: "English"
    }
  ]),
    chosenLang = ref(langs.value[0])

  const langsGetter = computed(() => langs.value),
    chosenLangGetter = computed(() => chosenLang.value)

  const changeLang = (id) => {
    chosenLang.value = langs.value.find(el => el.id == id)
  }

  return { langsGetter, chosenLangGetter, changeLang }
})