export const useWidget = (cb) => {
  turnstile.render('#cloudflareWidget', {
    sitekey: process.env.NODE_ENV == 'development' ? process.env.VUE_APP_LOCAL_KEY : '0x4AAAAAAAS0SZ9atIRIjyvA',
    theme: 'dark',
    // TODO better to test on real data
    callback: async function (token) {
      if (!token) return console.error('Some problems encountered with captcha');
      // let resp = await fetch(`${process.env.NODE_ENV == 'development' ? 'https://test.api.uhmi.org.ua' : 'https://api.uhmi.org.ua'}/api_key/?response_token=${token}${process.env.NODE_ENV == 'development' ? '&domain=localhost' : ''}`);
      let resp = await fetch(`${'https://testnew.api.uhmi.org.ua'}/api_key/?response_token=${token}${process.env.NODE_ENV == 'development' ? '&domain=localhost' : ''}`);
      let body = await resp.json();
      if (body.error) {
        alert(body.error);
        return console.error(body.error);
      }
      document.cookie = `APIToken=${ body.api_key }; max-age=${ 60 * 10 }; Secure; path=/`;
      document.querySelector("#cloudflareWidget").classList.add("hidden")

      setTimeout(() => {
        turnstile.reset();
      }, (1000 * 60 * 10) - (1000 * 10))

      if(cb) cb()
    },
  });
}