<template>
  <div class="region-type">
    <div class="region-type__inner">
      {{ props.regionTypes[chosenRegionTypeNumber].name?.[chosenLang.id] }}
      <svg
        class="region-type__icon"
        width="4"
        height="8"
        viewBox="0 0 4 8"
        fill="#FCFCFC"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.89391 4.3109L0.960528 7.87122C0.819055 8.04293 0.58969 8.04293 0.448231 7.87122L0.106102 7.45596C-0.0351304 7.28454 -0.0354022 7.00672 0.105498 6.8349L2.43025 3.99999L0.105498 1.1651C-0.0354021 0.993276 -0.0351303 0.715456 0.106102 0.544038L0.448232 0.128784C0.589705 -0.0429277 0.81907 -0.0429277 0.960528 0.128784L3.89391 3.68909C4.03536 3.8608 4.03536 4.13918 3.89391 4.3109Z"
        />
      </svg>
    </div>

    <div class="region-type__dropdown">
      <div
        class="region-type__dropdown-item"
        v-for="(item, counter) in props.regionTypes"
        @click="chosenRegionTypeNumber = counter; emits('change', item.value)"
      >
        {{ item.name?.[chosenLang.id] }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { useLangStore } from '@/stores/useLangStore';
import { computed, ref } from 'vue';

let props = defineProps(['regionTypes']),
  chosenRegionTypeNumber = ref(0),
  langStore = useLangStore()

const chosenLang = computed(() => langStore.chosenLangGetter)

let emits = defineEmits(['change'])
</script>

<style lang="scss" scoped>
.region-type {
  z-index: 2;
  margin-top: 40px;

  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  pointer-events: all;

  &:hover & {
    &__icon {
      opacity: 1;
    }

    &__dropdown {
      max-height: 118px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px 10px 18px 14px;
    border-radius: 6px;
    background: rgba(236, 112, 20, 0.3);
    backdrop-filter: blur(10px);
    cursor: pointer;
  }

  &__icon {
    opacity: 0.3;
    transition: .2s;
    margin-left: auto;
    rotate: 90deg;
  }

  &__dropdown {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 10px;
    width: 100%;
    overflow: hidden;
    max-height: 0;
    transition: .3s;

    &-item {
      padding: 18px 14px;
      border: 1px solid transparent;
      border-radius: 6px;
      background: rgba(236, 112, 20, 0.3);
      transition: .3s;
      backdrop-filter: blur(10px);
      cursor: pointer;

      &:hover {
        border: 1px solid rgba(255, 255, 255, 0.5);
      }
    }
  }
}
</style>