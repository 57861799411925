<template>
  <div
    class="popup__wrapper"
    id="YearsPopup"
    @click="useTogglePopup('YearsPopup')"
  >
    <div
      class="popup"
      @click.stop
    >
      <div class="popup__heading">
        <template v-if="chosenLang.id == 'ua'">
          Оберіть рік
        </template>
        <template v-else-if="chosenLang.id == 'en'">
          Choose a year
        </template>
      </div>

      <div class="years">
        <div
          class="year"
          v-for="year in (2024 + 1 - 1980)"
          @click="updateYears(year)"
          :class="{ 'year--active': mapStore.yearsGetter.includes(year + 1980 - 1) }"
        >
          {{ year + 1980 - 1 }}
        </div>
      </div>

      <div
        class="popup__btn"
        @click="useTogglePopup('YearsPopup')"
      >
        <template v-if="chosenLang.id == 'ua'">
          Обрати
        </template>
        <template v-else-if="chosenLang.id == 'en'">
          Select
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useTogglePopup } from "@/composables/popup";
import { useLangStore } from "@/stores/useLangStore";
import { useMapStore } from "@/stores/useMapStore";
import { computed, onMounted, ref } from "vue";

const props = defineProps(['yearIndex'])

const langStore = useLangStore(),
  mapStore = useMapStore()

const chosenLang = computed(() => langStore.chosenLangGetter)

const updateYears = (year) => {
  let exp = [mapStore.yearsGetter[+!props.yearIndex], (year + 1980 - 1)].sort((a, b) => a - b)
  if(mapStore.selectedTimeModeGetter == 'One Year') exp = [year + 1980 - 1];
  mapStore.updateYears(exp)
}
</script>

<style lang="scss" scoped>
.popup {
  position: absolute;
  width: 1084px;
  height: 700px;
  top: 140px;
  margin-left: calc((100vw - 1084px) / 2);
  scale: 0.5;
  transition: .5s;
  background-color: #FFFBDE;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: Roboto;
  padding: 12px;

  &__wrapper {
    position: absolute;
    width: 100vw;
    height: 100dvh;
    left: 0;
    top: 0;
    background-color: #00000080;
    z-index: -1;
    transition: .5s;
    opacity: 0;

    &--active {
      opacity: 1;
      z-index: 999;
      cursor: pointer;

      .popup {
        scale: 1;
        cursor: auto;
      }
    }
  }

  &__heading {
    font-size: 40px;
    text-align: center;
    font-weight: 500;
  }

  &__btn {
    width: fit-content;
    font-size: 26px;
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 4px 8px;
    border: 2px solid #EC7014;
    border-radius: 12px;
    background-color: #993404a0;
    color: #fff;
  }
}

.year {
  width: fit-content;
  margin: 0 auto;
  padding: 4px 8px;
  border: 2px solid #a0a0a0;
  font-size: 18px;
  border-radius: 12px;

  &s {
    max-height: 80%;
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    grid-auto-flow: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
  }

  &--active {
    background-color: #993404a0;
    border-color: #EC7014;
    color: #fff;
  }
}

@media screen and (max-width: 1100px) {
  .popup {
    width: 90vw;
    margin-left: 5vw;
  }
}

@media screen and (max-height: 900px) {
  .popup {
    top: calc(60px + 2.5dvh);
    height: calc(100dvh - 60px - 5dvh);
  }
}</style>